<!--@Time : 2021/11/11 10:41-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
    <div>
        <Header></Header>
        <v-row>
            <v-col cols="8" md="8" lg="8">
                <div id="container" style="position: relative">
                    <Loading v-if="!data.length" :loading="loading"></Loading>
                </div>
            </v-col>

            <v-col cols="4" md="4" lg="4">
                <p>
                    <v-icon>mdi-medal</v-icon>
                    {{ $t("$vuetify.language.medal") }}
                </p>
                <v-data-table
                    :headers="headers"
                    :items="desserts"
                    :items-per-page="10"
                    class="elevation-0"
                >
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Header from "@/components/Header";
import Loading from "@/components/Loading";
import { Chart } from "@antv/g2";
import { DataView } from "@antv/data-set";
export default {
    name: "Standing",
    components: { Header, Loading },
    data() {
        return {
            data: [],
            chart: null,
            loading: "loading...",
            headers: [
                { text: "Team", value: "team" },
                { text: "Gold", value: "gold", align: "center" },
                { text: "Silver", value: "silver", align: "center" },
                { text: "Bronze", value: "bronze", align: "center" },
                { text: "Total", value: "total", align: "center" },
            ],
            desserts: [],
            ranking: [
                ["CUMT&AGH", { gold: 0, silver: 0, bronze: 0, all: 0 }],
                ["Y-Team", { gold: 0, silver: 0, bronze: 0, all: 0 }],
                [
                    "DUT and Associates",
                    { gold: 0, silver: 0, bronze: 0, all: 0 },
                ],
                ["HUI", { gold: 0, silver: 0, bronze: 0, all: 0 }],
                ["SRF", { gold: 0, silver: 0, bronze: 0, all: 0 }],
                ["Tianjin U+", { gold: 0, silver: 0, bronze: 0, all: 0 }],
                ["CCMH", { gold: 0, silver: 0, bronze: 0, all: 0 }],
                ["BJTU+", { gold: 0, silver: 0, bronze: 0, all: 0 }],
                ["HIT&UCB", { gold: 0, silver: 0, bronze: 0, all: 0 }],
                ["DTU-SUDA", { gold: 0, silver: 0, bronze: 0, all: 0 }],
                ["THU", { gold: 0, silver: 0, bronze: 0, all: 0 }],
                ["XJTU+", { gold: 0, silver: 0, bronze: 0, all: 0 }],
                ["Solar Ark", { gold: 0, silver: 0, bronze: 0, all: 0 }],
                ["Hope Land", { gold: 0, silver: 0, bronze: 0, all: 0 }],
                ["Qiju 3.0", { gold: 0, silver: 0, bronze: 0, all: 0 }],
            ],
        };
    },
    created() {
        this.initWebSocket();
    },
    mounted() {
        this.setChart();

        const ths = document.querySelectorAll("th");
        const colors = ["transparent", "gold", "silver", "peru", "transparent"];
        for (let i = 0; i < colors.length; i++) {
            ths[i].style.background = colors[i];
            ths[i].style.borderRadius = "15px";
            ths[i].style.height = "30px";
            ths[i].style.border = "0";
        }
    },

    beforeDestroy() {
        //离开路由之前断开websocket连接
        this.websock.close();
    },

    watch: {},
    computed: {},
    methods: {
        setChart() {
            this.chart = new Chart({
                container: "container",
                autoFit: true,
                height: 600,
            });
            this.chart.data(this.data);
            this.chart.scale("score", {
                min: 0,
                max: 1000,
                tickInterval: 100,
            });
            this.chart.legend({
                position: "bottom",
                flipPage: false,
                items: [
                    { name: "Penalty", marker: { style: { fill: "#e74c3c" } } },
                    {
                        name: "Architecture",
                        marker: { style: { fill: "#6395F9" } },
                    },
                    {
                        name: "Engineering & Construction",
                        marker: { style: { fill: "#61D9AB" } },
                    },
                    { name: "Energy", marker: { style: { fill: "#657798" } } },
                    {
                        name: "Communications",
                        marker: { style: { fill: "#F6C022" } },
                    },
                    {
                        name: "Market Potential",
                        marker: { style: { fill: "#7666F9" } },
                    },
                    {
                        name: "Indoor Environment",
                        marker: { style: { fill: "#74CBED" } },
                    },
                    {
                        name: "Renewable Heating & Cooling",
                        marker: { style: { fill: "#9967BD" } },
                    },
                    {
                        name: "Home Life",
                        marker: { style: { fill: "#FF9D4E" } },
                    },
                    {
                        name: "Interactive Experience",
                        marker: { style: { fill: "#299999" } },
                    },
                    {
                        name: "Energy Self-Sufficiency",
                        marker: { style: { fill: "#FF9EC6" } },
                    },
                ],
            });
            this.chart.coordinate().transpose();
            this.chart.tooltip({
                shared: true,
                showMarkers: false,
                customItems: (items) => {
                    items.forEach((item) => {
                        item.value = Number(item.value).toFixed(3);
                    });
                    return items;
                },
            });
            this.chart.interaction("active-region");
            this.chart
                .interval()
                .adjust("stack")
                .position("team*score")
                .color("contest*team", (contest) => {
                    if (contest === "Penalty") {
                        return "#e74c3c";
                    }
                    if (contest === "Architecture") {
                        return "#6395F9";
                    }
                    if (contest === "Engineering & Construction") {
                        return "#61D9AB";
                    }
                    if (contest === "Energy") {
                        return "#657798";
                    }
                    if (contest === "Communications") {
                        return "#F6C022";
                    }
                    if (contest === "Market Potential") {
                        return "#7666F9";
                    }
                    if (contest === "Indoor Environment") {
                        return "#74CBED";
                    }
                    if (contest === "Renewable Heating & Cooling") {
                        return "#9967BD";
                    }
                    if (contest === "Home Life") {
                        return "#FF9D4E";
                    }
                    if (contest === "Interactive Experience") {
                        return "#299999";
                    }
                    if (contest === "Energy Self-Sufficiency") {
                        return "#FF9EC6";
                    }
                    if (contest === "Total") {
                        return "rgba(255,255,255,0)";
                    }
                })
                .label("score", (score) => {
                    if (score === 0) {
                        return null;
                    }
                    return {
                        content: score.toFixed(3),
                        fill: "#07021a",
                        position: "left",
                    };
                });
            this.chart.render();
        },

        //初始化websocket
        async initWebSocket() {
            const url = `${process.env.VUE_APP_WS_URL}/ws/standing/?token=${this.$store.getters.token}`;
            this.websock = new WebSocket(url);
            this.websock.onmessage = this.WebsocketOnMessage;
            this.websock.onopen = this.WebsocketOnOpen;
            this.websock.onerror = this.WebsocketOnError;
            this.websock.onclose = this.WebsocketClose;
        },
        WebsocketOnOpen(e) {
            console.log("建立连接", e);
            //连接建立之后执行send方法发送数据
            let actions = { type_id: "1" };
            this.WebsocketSend(JSON.stringify(actions));
        },
        WebsocketOnError() {
            //连接建立失败重连
            this.initWebSocket();
        },
        WebsocketOnMessage(event) {
            //数据接收
            this.ranking = JSON.parse(event.data)["ranking"];
            this.data = new DataView()
                .source(JSON.parse(event.data)["standing"])
                .transform({
                    type: "fold",
                    fields: [
                        "Penalty",
                        "Architecture",
                        "Engineering & Construction",
                        "Energy",
                        "Communications",
                        "Market Potential",
                        "Indoor Environment",
                        "Renewable Heating & Cooling",
                        "Home Life",
                        "Interactive Experience",
                        "Energy Self-Sufficiency",
                        "Total",
                    ], // 展开字段集
                    key: "contest", // key字段
                    value: "score", // value字段
                    retains: ["team"], // 保留字段集，默认为除 fields 以外的所有字段
                }).rows;
            this.chart.changeData(this.data);

            this.ranking.forEach((item) => {
                this.desserts.push({
                    team: item[0],
                    gold: item[1]["gold"],
                    silver: item[1]["silver"],
                    bronze: item[1]["bronze"],
                    total: item[1]["all"],
                });
            });
        },
        WebsocketSend(Data) {
            //数据发送
            this.websock.send(Data);
        },
        WebsocketClose(e) {
            //关闭
            console.log("断开连接", e);
        },
    },
};
</script>

<style scoped>
</style>